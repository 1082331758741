import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import SEO from '@components/SEO';
import FAQsModule from '@modules/FAQs/Main';

const FAQs = () => (
  <Layout isReleased={true} isMobileComingSoon={false}>
    <SEO title="FAQs" description="FAQs" />
    <div className="FAQs-section">
      <FAQsModule />
    </div>
  </Layout>
);

export default FAQs;
